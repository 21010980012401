import React, { useEffect, useState } from "react";
import "../css/Horizontal_Scrolling.css";
import "../css/Desktop.css";
import "../css/CustomCursor.css";
import "../css/AnimationPanel.css";
import "../css/DemoEnding.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MainPage from "../Components/MainPage";
import CustomCursor from "../Components/CustomCursor";
import DetailsPage from "../Components/DetailsPage/DetailsPage";
import TransitionAnimation from "../Components/TransitionAnimation/TransitionAnimation";

gsap.registerPlugin(ScrollTrigger);

const Desktop = () => {
  const [animationOver, setAnimationOver] = useState(false);
  const [currentPage, setCurrentPage] = useState("Main");
  const [transitionAnimation, setTransitionAnimation] = useState(false);
  const [newPage, setNewPage] = useState(null);
  // eslint-disable-next-line
  const [cursorColor, setCursorColor] = useState("#FFFFFF");

  function changeCurrentPage(newValue) {
    if (newValue !== currentPage) {
      setTransitionAnimation(true);
      if (!animationOver) setAnimationOver(true);
      setNewPage(newValue);
    }
  }

  useEffect(() => {
    const navigationEntries = performance.getEntriesByType("navigation");
    const isPageReload =
      navigationEntries.length > 0 && navigationEntries[0].type === "reload";

    if (isPageReload) {
      setAnimationOver(true);
      ScrollTrigger.refresh();
    }
  }, []);

  useEffect(() => {
    if (currentPage === "Main" && newPage) {
    }
  }, [currentPage, newPage, transitionAnimation]);

  return (
    <div style={{ width: "max-content" }}>
      <CustomCursor color={cursorColor} />
      {transitionAnimation === true && (
        <TransitionAnimation
          newPage={newPage}
          setTransitionAnimation={setTransitionAnimation}
          setCurrentPage={setCurrentPage}
        />
      )}
      {currentPage === "Main" && (
        <MainPage
          animationOver={animationOver}
          setCurrentPage={changeCurrentPage}
          setAnimationOver={setAnimationOver}
        />
      )}
      {currentPage === "Branding" && (
        <DetailsPage
          setCurrentPage={changeCurrentPage}
          TextImageBlock_url={"/img/InstaDemo/Modeling/4.jpg"}
          black_box_title="Branding"
          black_box_content="Concevoir, élaborer et façonner une identité pour nos clients. Inventer des codes uniques et distinctifs pour mettre en lumière le caractère et l'unicité de chaque entreprise"
          TextImageBlock_titre="Vision"
          TextImageBlock_text="Nous concevons un branding de haute qualité, personnalisé pour répondre aux besoins de chaque client. En collaborant étroitement avec nos clients, nous nous assurons que chaque élément de la marque soit parfaitement aligné avec leur vision et leurs objectifs"
          ImageRectangle_urls={[
            [
              "/img/InstaDemo/Modeling/5.jpg",
              "/img/InstaDemo/Modeling/6.jpg",
              "Monaco GP / BuddhaBar",
              "NotreDame.Music / CBeachCannes",
            ],
            [
              "/img/InstaDemo/Modeling/7.jpg",
              "/img/InstaDemo/Modeling/8.jpg",
              "Monaco GP / BuddhaBar",
              "Vladimir Cauchemar / Baôli",
            ],
          ]}
        />
      )}
      {currentPage === "Modeling" && (
        <DetailsPage
          setCurrentPage={changeCurrentPage}
          urls={[
            "/img/InstaDemo/Modeling/1.jpg",
            "/img/InstaDemo/Modeling/2.jpg",
            "/img/InstaDemo/Modeling/3.jpg",
          ]}
          black_box_title={"Modeling"}
          black_box_content="Nous choisissons minutieusement des mannequins professionnels, en totale harmonie avec l'image de votre marque, afin de garantir à votre marque une représentation authentique et engageante"
          TextImageBlock_titre="Mannequin"
          TextImageBlock_text="Notre agence de communication vous offre un service de modeling haut de gamme, parfait à vos campagnes publicitaires et événements. Nous sélectionnons avec soin des mannequins professionnels, en accord avec l'image de votre marque, afin de garantir une représentation authentique et séduisante"
          TextImageBlock_url={"/img/InstaDemo/Modeling/4.jpg"}
          ImageRectangle_urls={[
            [
              "/img/InstaDemo/Modeling/5.jpg",
              "/img/InstaDemo/Modeling/6.jpg",
              "Monaco GP / BuddhaBar",
              "NotreDame.Music / CBeachCannes",
            ],
            [
              "/img/InstaDemo/Content/9.jpg",
              "/img/InstaDemo/Content/10.jpg",
              "Monaco GP / BuddhaBar",
              "Vladimir Cauchemar / Baôli",
            ],
          ]}
        />
      )}
      {currentPage === "Content" && (
        <DetailsPage
          setCurrentPage={changeCurrentPage}
          urls={[
            "/img/InstaDemo/Content/1.jpg",
            "/img/InstaDemo/Content/2.jpg",
            "/img/InstaDemo/Content/3.jpg",
          ]}
          black_box_title={"Content"}
          TextImageBlock_titre="MultiMédia"
          black_box_content="Publier des contenus ciblés pour nos audiences sur chaque plateforme. Concevoir et affiner nos messages pour susciter l'engagement, en misant sur des contenus créatifs et des légendes percutantes"
          TextImageBlock_text="Nous pensons vos contenus multimédias et les développons de manière stratégique pour maximiser l'engagement de vos réseaux sociaux.  En travaillant chaque détail, nous nous assurons que nos communications attirent l’attention et renforcent la connexion avec notre communauté"
          TextImageBlock_url={"/img/InstaDemo/Content/4.jpg"}
          ImageRectangle_urls={[
            [
              "/img/InstaDemo/Content/5.jpg",
              "/img/InstaDemo/Content/6.jpg",
              "Baôli /  NightLife",
              "CoachellitoProd / NotreDame.Music",
            ],
            [
              "/img/InstaDemo/Content/7.jpg",
              "/img/InstaDemo/Content/8.jpg",
              "Twiga / DJ Leska",
              "Twiga / MontRouge.Music",
            ],
          ]}
        />
      )}
    </div>
  );
};

export default Desktop;
