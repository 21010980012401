import ChangingImage from "./ChangingImage/ChangingImage";
import ImageWithFadingText from "./ImageWithFadingText";
import ImageWithScrollingText from "./ImageWithScrollingText";
import { useState, useLayoutEffect, useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AnimationPanel from "./AnimationPanel/AnimationPanel";
import ContactForm from "./ContactForm/ContactForm";

gsap.registerPlugin(ScrollTrigger);

const MainPageComponents = ({
  gsapId = "",
  mainTween = null,
  setCursorColor = () => {},
  setCurrentPage = () => {},
}) => {
  return (
    <>
      <ChangingImage fading_text="Nos Services" />
      <ImageWithFadingText
        setCurrentPage={setCurrentPage}
        link={"Branding"}
        setCursorColor={setCursorColor}
        gsapId={"first" + gsapId}
        mainTween={mainTween}
        url="/img/Image_2.png"
        titre="Branding"
        text="Notre agence de communication, spécialisée en branding, transforme votre identité visuelle et digitale avec des solutions sur mesure. Nous révélons l'histoire unique de chaque marque grâce à des stratégies créatives, des logos distinctifs et des campagnes mémorables, pour une présence forte et cohérente sur tous les canaux"
      />
      <ImageWithFadingText
        setCurrentPage={setCurrentPage}
        link={"Modeling"}
        setCursorColor={setCursorColor}
        mainTween={mainTween}
        gsapId={"second" + gsapId}
        url="/img/Image_3.png"
        titre="Modeling"
        text="Notre agence de communication vous propose un service de modeling haut de gamme, idéal pour donner vie à vos campagnes publicitaires et vos événements. Nous sélectionnons des mannequins professionnels, adaptés à l'image et aux valeurs de votre marque, pour garantir une représentation authentique et attrayante"
      />
      <ImageWithFadingText
        setCurrentPage={setCurrentPage}
        link={"Content"}
        setCursorColor={setCursorColor}
        mainTween={mainTween}
        gsapId={"third" + gsapId}
        url="/img/Image_4.png"
        titre="Content Creation"
        text="Notre agence de communication excelle dans la création de contenus digitaux adaptés aux réseaux sociaux. Nous concevons des contenus engageants qui captivent votre audience. De la production de vidéos virales aux publications graphiques, chaque création est pensée pour maximiser l'engagement et votre visibilité"
      />
      <ChangingImage
        urls={["/img/Image_Déroulant_2_1.png", "/img/Image_Déroulant_2_2.png"]}
        id={2}
        fading_text="Nos Clients"
      />
      <ImageWithScrollingText
        setCursorColor={setCursorColor}
        mainTween={mainTween}
        gsapId={"fourth" + gsapId}
        url="/img/Image_5.png"
        texts={[
          " bronx pizza |",
          "nice |",
          "branding |",
          "content creation |",
          "photo |",
          "digital  |",
        ]}
      />
      <ImageWithScrollingText
        setCursorColor={setCursorColor}
        mainTween={mainTween}
        gsapId={"fifth" + gsapId}
        url="/img/Image_6.png"
        texts={[
          " Twiga |",
          "Montecarlo |",
          "video |",
          "content creation |",
          "photo  |",
        ]}
      />
      <ImageWithScrollingText
        setCursorColor={setCursorColor}
        mainTween={mainTween}
        gsapId={"sixth" + gsapId}
        url="/img/Image_7.png"
        texts={[
          " Bâoli |",
          "Cannes |",
          "CLUB |",
          "ROOFTOP |",
          "RESTAURANT  |;",
        ]}
      />
      <ImageWithScrollingText
        mainTween={mainTween}
        gsapId={"seventh" + gsapId}
        url="/img/Image_8.png"
        texts={[
          " Cachellito production |",
          "monaco |",
          "Different Music |",
          "Different Vision |",
          "VIP  |;",
        ]}
      />
      <ContactForm />
    </>
  );
};

const MainPage = ({
  setCursorColor = () => {},
  animationOver,
  setCurrentPage = () => {},
  setAnimationOver = () => {},
}) => {
  const [mainTween, setMainTween] = useState(null);
  const racesRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (animationOver) window.scrollTo(1050, 0);
  }, [animationOver]);

  useLayoutEffect(() => {
    const races = racesRef.current;

    function getScrollAmount() {
      let racesWidth = races.scrollWidth;
      return -(racesWidth - window.innerWidth);
    }

    const tween = gsap.to(races, {
      x: getScrollAmount(),
      duration: 3,
      ease: "none",
    });

    setMainTween(tween);

    const scrollTrigger = ScrollTrigger.create({
      trigger: wrapperRef.current,
      start: "top top",
      end: () => `+=${getScrollAmount() * -1}`,
      pin: true,
      toggleActions: "play none none reset",
      animation: tween,
      scrub: 1,
    });

    return () => {
      tween.kill();
      scrollTrigger.kill();

      setMainTween(null);
      ScrollTrigger.clearMatchMedia();
    };
  }, []);

  useEffect(() => {
    var disableScrollUpPoint = 1050;

    function preventGoingBack() {
      var scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (scrollTop < disableScrollUpPoint) {
        window.scrollTo(0, disableScrollUpPoint);
      }
    }
    function handleScroll() {
      var scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (scrollTop > disableScrollUpPoint) {
        setAnimationOver(true);
      }
    }

    if (animationOver) {
      window.addEventListener("scroll", preventGoingBack);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", preventGoingBack);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [animationOver, setAnimationOver]);

  useEffect(() => {
    const handleResize = () => {
      window.location.reload();
      ScrollTrigger.refresh();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <AnimationPanel
        animationOver={animationOver}
        setCurrentPage={setCurrentPage}
      />
      <div
        className="gsap_scrolling_wrapper"
        style={{ transform: "translate(0)" }}
        ref={wrapperRef}
      >
        <div className="gsap_scrolling" ref={racesRef}>
          <div className="behind_animation" />
          <MainPageComponents
            mainTween={mainTween}
            setCursorColor={setCursorColor}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
