import { React, useEffect } from "react";
import gsap from "gsap";

const TransitionSecondPart = ({ setTransitionAnimation }) => {
  useEffect(() => {
    const comingDown = gsap.timeline();
    comingDown.to(".second_part .part", {
      width: "10vw",
      stagger: 0.3,
      ease: "power1",
      duration: 0.4,
    });

    comingDown.to(".second_part", {
      opacity: 0,
      duration: 0.7,
      onComplete: () => {
        setTransitionAnimation(false);
      },
    });

    comingDown.resume();
    return () => {
      comingDown.kill();
    };
  });
  return (
    <div className="transition second_part">
      <div className="part" />
      <div className="part" />
      <div className="part" />
      <div className="part" />
      <div className="part" />
    </div>
  );
};

export default TransitionSecondPart;
