import { React, useEffect, useRef } from "react";
import gsap from "gsap";

const PassiveAnimationPanel = ({ setCurrentPage = () => {} }) => {
  const panelRef = useRef();
  const linksRef = useRef();

  const link1Ref = useRef();
  const link2Ref = useRef();
  const link3Ref = useRef();
  const link4Ref = useRef();

  useEffect(() => {
    const t1 = gsap.timeline({ paused: true });
    t1.fromTo(
      panelRef.current,
      { width: "10vw" },
      { width: "50vw", duration: 0.3, ease: "power1" }
    );

    panelRef.current.addEventListener("mouseenter", () => {
      t1.restart();
    });
    panelRef.current.addEventListener("mouseleave", () => {
      t1.reverse();
    });

    const linkRefs = [link1Ref, link2Ref, link3Ref, link4Ref];

    linkRefs.forEach((linkRef, index) => {
      gsap.set(linkRef.current, { opacity: 0 });

      const h3 = linkRef.current.previousElementSibling;

      const linkOpacity = gsap.fromTo(
        linkRef.current,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.3,
          paused: true,
          ease: "power2.in",
        }
      );

      const linkrotation = gsap.fromTo(
        linkRef.current,
        { rotation: 0 },
        {
          duration: 0.3,
          rotation: 135,
          paused: true,
        }
      );

      h3.addEventListener("mouseenter", () => {
        linkOpacity.play();
        linkrotation.play();
      });

      h3.addEventListener("mouseleave", () => {
        linkOpacity.reverse();
        linkrotation.reverse();
      });
    });
  }, []);

  return (
    <div className={`animation_panel passive`} ref={panelRef}>
      <div className="animation_video">
        <img src="/img/FlyTips_Logo.png" alt="" />
      </div>
      <div className="bars">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <div className="links" ref={linksRef}>
        <div className="link_wrapper">
          <div
            className="link"
            onClick={() => {
              setCurrentPage("Main");
            }}
          >
            <h3>Accueil</h3>
            <span ref={link4Ref}></span>
          </div>
          <div
            className="link"
            onClick={() => {
              setCurrentPage("Branding");
            }}
          >
            <h3>Branding</h3>

            <span ref={link1Ref}></span>
          </div>
          <div className="link" onClick={() => setCurrentPage("Modeling")}>
            <h3>Modeling</h3>
            <span ref={link2Ref}></span>
          </div>
          <div className="link" onClick={() => setCurrentPage("Content")}>
            <h3>Content</h3>
            <span ref={link3Ref}></span>
          </div>
        </div>
        <div className="socials_link">
          <a
            href="https://www.instagram.com"
            target="_blank"
            className="insta_link"
            rel="noreferrer"
          >
            <div className="insta image_wrapper">
              <img src="/img/insta_logo.png" alt="Instagram Logo" />
            </div>
          </a>
        </div>
        <div className="CGU">
          <h3>CGU</h3>
        </div>
      </div>
    </div>
  );
};

export default PassiveAnimationPanel;
