import { React, useEffect } from "react";
import gsap from "gsap";

const TransitionFirstPart = ({ setCurrentAnimation = () => {} }) => {
  useEffect(() => {
    const comingDown = gsap.to(".first_part .part", {
      height: "100%",
      stagger: 0.3,
      duration: 0.4,
      ease: "power1",
      onComplete: () => {
        setCurrentAnimation(2);
      },
    });

    return () => {
      comingDown.kill();
    };
  });
  return (
    <div className="transition first_part">
      <div className="part" />
      <div className="part" />
      <div className="part" />
      <div className="part" />
      <div className="part" />
    </div>
  );
};

export default TransitionFirstPart;
