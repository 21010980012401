import React from "react";
import "../../css/DetailsPage/ImageRectangle.css";

const ImageRectangle = ({
  urls = [
    [
      "/img/Image_2.png",
      "/img/Image_2.png",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus vel justo tempus accumsan",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus vel justo tempus accumsan",
    ],
    [
      "/img/Image_2.png",
      "/img/Image_2.png",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus vel justo tempus accumsan",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus vel justo tempus accumsan",
    ],
  ],
}) => {
  return (
    <div className="image_rectangle">
      {urls.map((row_src, index) => (
        <div className={`row ${index % 2 === 1 ? "reverse" : ""}`} key={index}>
          <div className="big image_wrapper">
            <div className="wrapper">
              <div className="image_size">
                <img src={row_src[0]} alt="" key={row_src[0]} />
                <div className="inside_text">
                  <h4 key={row_src[2]}>{row_src[2]}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="small image_wrapper">
            <div className="wrapper">
              <div className="image_size">
                <img src={row_src[1]} alt="" key={row_src[1]} />
                <div className="inside_text">
                  <h4 key={row_src[3]}>{row_src[3]}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageRectangle;
