import { React, useEffect, useRef } from "react";
import "../../css/ContactForm/ContactForm.css";
import ChangingImageBackground from "../ChangingImage/ChangingImageBackground";
import gsap from "gsap";

const ContactForm = () => {
  const fadingWrapperRef = useRef();
  const contactUsRef = useRef();
  const inputRef = useRef();
  const validateRef = useRef();
  const socialsRef = useRef();

  useEffect(() => {
    const fadinTimeline = gsap.timeline({
      paused: true,
    });

    fadinTimeline.fromTo(
      fadingWrapperRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.3, ease: "power1" }
    );

    fadinTimeline.fromTo(
      contactUsRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.3, ease: "power1" }
    );
    fadinTimeline.fromTo(
      inputRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.3, ease: "power1" }
    );
    fadinTimeline.fromTo(
      validateRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.3, ease: "power1" }
    );

    fadinTimeline.fromTo(
      socialsRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.3, ease: "power1" }
    );

    fadingWrapperRef.current.addEventListener("mouseenter", () => {
      fadinTimeline.play();
    });
    fadingWrapperRef.current.addEventListener("mouseleave", () => {
      fadinTimeline.reverse();
    });

    return () => {
      fadinTimeline.kill();
    };
  });
  return (
    <div className="contact_form changing_image_background">
      <div className="wrapper">
        <ChangingImageBackground />
        <div className="fading_wrapper" ref={fadingWrapperRef}>
          <div className="submit_group">
            <h1 ref={contactUsRef}>Nous Contacter</h1>
            <input type="text" placeholder="Email" ref={inputRef} />
            <br />
            <button ref={validateRef}>Valider</button>
          </div>
          <div className="socials_link" ref={socialsRef}>
            <a
              href="https://www.instagram.com"
              target="_blank"
              className="insta_link"
              rel="noreferrer"
            >
              <div className="insta image_wrapper">
                <img src="/img/insta_logo.png" alt="Instagram Logo" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
