import { React, useEffect, useState, useLayoutEffect } from "react";
import "../../css/TransitionAnimation/TransitionAnimation.css";
import TransitionFirstPart from "./TransitionFirstPart";
import TransitionSecondPart from "./TransitionSecondPart";

const TransitionAnimation = ({
  setCurrentPage = () => {},
  newPage = "",
  setTransitionAnimation = () => {},
}) => {
  const [currentAnimation, setCurrentAnimation] = useState(1);

  useEffect(() => {
    if (currentAnimation === 2) {
      if (newPage === "Main")
        window.scrollTo({ top: 1050, left: 0, behavior: "instant" });
      else window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      setCurrentPage(newPage);
    }
  }, [currentAnimation, newPage, setCurrentPage]);

  useLayoutEffect(() => {
    console.log("Starting Transition");
  });

  return (
    <section className="TransitionAnimation">
      <div className="transition_wrapper">
        {currentAnimation === 1 && (
          <TransitionFirstPart setCurrentAnimation={setCurrentAnimation} />
        )}
        {currentAnimation === 2 && (
          <TransitionSecondPart
            setCurrentAnimation={setCurrentAnimation}
            setTransitionAnimation={setTransitionAnimation}
          />
        )}
      </div>
    </section>
  );
};

export default TransitionAnimation;
