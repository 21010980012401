import { React, useEffect, useState } from "react";
import "../../css/DetailsPage/ChangingImageAndBlackText.css";

const ChangingImageAndBlackText = ({
  urls = [
    "/img/Image_Déroulant_1_1.png",
    "/img/Image_Déroulant_1_2.png",
    "/img/Image_Déroulant_1_3.png",
  ],
  black_box_title = "Branding",
  black_box_content = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer at turpis nec enim ornare hendrerit. Nulla lacinia viverra odio eget.",
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % urls.length);
    }, 3000);

    return () => clearInterval(interval);
  });
  return (
    <>
      <div className="ChangingImageAndBlackText">
        <div className="row top">
          <div className="img_wrapper">
            {urls.map((url, index) => (
              <img
                key={index}
                src={url}
                alt=""
                className={index === currentImageIndex ? "active" : ""}
              />
            ))}
          </div>
          <div className="black box">
            <h1>{black_box_title}</h1>
            <h3>{black_box_content}</h3>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangingImageAndBlackText;
