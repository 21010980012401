import React, { useEffect, useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "../../css/AnimationPanel.css";
import PassiveAnimationPanel from "./PassivAnPanel";

gsap.registerPlugin(ScrollTrigger);

const ActiveAnimationPanel = () => {
  const bars = useRef();

  useEffect(() => {
    const barElements = bars.current.querySelectorAll(".bar");
    const boxFade = gsap.fromTo(
      barElements,
      {
        opacity: 0,
      },
      { opacity: 1, duration: 1, stagger: 0.4 }
    );
    return () => {
      boxFade.kill();
    };
  });

  return (
    <div className={`animation_panel`}>
      <div className="animation_video">
        <img src="/img/FlyTips_Logo.png" alt=""></img>
      </div>
      <div className="text">
        <h1>
          <span className="first">F</span>
          <span className="second">L</span>
          <span className="third">Y</span>
          <span className="fourth">T</span>
          <span className="fifth">I</span>
          <span className="sixth">P</span>
          <span className="seventh">S</span>
        </h1>
      </div>
      <div className="bars" ref={bars}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </div>
  );
};

const AnimationPanel = ({ animationOver, setCurrentPage = () => {} }) => {
  useLayoutEffect(() => {
    const animation = gsap.fromTo(
      ".animation_panel",
      { width: "100vw" },
      {
        width: "10vw",
        scrollTrigger: {
          trigger: ".animation_panel",
          start: "top+=450px top",
          end: "+=650px",
          scrub: 1,
          toggleActions: "play none none reset",
          onLeave: (self) => {
            gsap.set(".animation_panel", { width: "10vw" });
          },
        },
      }
    );

    return () => {
      animation.kill();
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  useLayoutEffect(() => {
    const hasRun = sessionStorage.getItem("hasRun");
    var scrollTop = window.scrollY || document.documentElement.scrollTop;

    if (scrollTop < 1050) {
      sessionStorage.setItem("hasRun", "false");
    }
    if (!hasRun && !animationOver) {
      gsap.timeline().fromTo(
        ".text span",
        { opacity: 0 },
        {
          "font-size": "1em",
          opacity: 1,
          scale: 1,
          ease: "power1.out",
          stagger: 0.3,
        }
      );
      sessionStorage.setItem("hasRun", "true");
    }

    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".animation_panel",
        start: "top top",
        end: "+=400px",
        scrub: 1,
        toggleActions: "play none none reset",
      },
    });

    if (!animationOver) {
      t1.addLabel("start").to(".text span", {
        opacity: 0,
        "font-size": "0.8em",
        scale: 0.5,
        stagger: -0.3,
      });
    }
    ScrollTrigger.refresh();

    return () => {
      t1.kill();
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  useEffect(() => {
    var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    function preventDefault(e) {
      e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    var supportsPassive = false;
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          // eslint-disable-next-line
          get: function () {
            supportsPassive = true;
          },
        })
      );
    } catch (e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    function disableScroll() {
      window.addEventListener("DOMMouseScroll", preventDefault, false);
      window.addEventListener(wheelEvent, preventDefault, wheelOpt);
      window.addEventListener("touchmove", preventDefault, wheelOpt);
      window.addEventListener("keydown", preventDefaultForScrollKeys, false);
    }

    function enableScroll() {
      window.removeEventListener("DOMMouseScroll", preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener("touchmove", preventDefault, wheelOpt);
      window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
    }

    if (!animationOver) disableScroll();

    const timeout = setTimeout(() => {
      enableScroll();
    }, 2300); //Time to paralyse scrolling

    return () => {
      clearTimeout(timeout);
      enableScroll();
    };
  }, [animationOver]);

  return (
    <section className="AnimationPanel">
      {!animationOver && <ActiveAnimationPanel />}
      {animationOver && (
        <PassiveAnimationPanel setCurrentPage={setCurrentPage} />
      )}
    </section>
  );
};

export default AnimationPanel;
