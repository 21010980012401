import React, { useEffect, useRef, useLayoutEffect } from "react";
import "../css/ImageWithFadingText.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const ImageWithFadingText = ({
  setCurrentPage = () => {},
  link = "Main",
  mainTween = null,
  url = "/img/Image_1.png",
  text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed volutpat odio. Nunc vestibulum a lectus et dapibus. Morbi malesuada arcu eget molestie consectetur. Ut sed justo a felis efficitur euismod.Nunc vestibulum a lectus et dapibus. Morbi malesuada arcu eget molestie consectetur.",
  titre = "Titre Demo",
  gsapId,
  setCursorColor = () => {},
}) => {
  const main = useRef();
  const canvasRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    const img = new Image();

    img.src = url;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
    };

    function updateSVGColor(event) {
      setCursorColor("#FFFFFF");
    }

    canvas.addEventListener("mouseenter", updateSVGColor);

    return () => {
      canvas.removeEventListener("mouseenter", updateSVGColor);
    };
  }, [setCursorColor, url]);

  useLayoutEffect(() => {
    function calculatePosition() {
      const viewportWidth = window.innerWidth;
      const ninetyFiveVW = 0.05 * viewportWidth;
      const result = ninetyFiveVW + 800;
      console.log("left " + result + "px");
      return "left " + result + "px";
    }

    if (mainTween) {
      gsap.to(`.image_wrapper.${gsapId}`, {
        scrollTrigger: {
          containerAnimation: mainTween,
          trigger: `.image_wrapper.${gsapId}`,
          start: "left 95%",
          end: "+=400",
          scrub: 2,
          id: `.image_wrapper.${gsapId}`,
          toggleActions: "play none none reset",
        },
        transform: "translateY(0)",
        opacity: 1,
      });
      gsap.to(`.text_block.${gsapId}`, {
        scrollTrigger: {
          containerAnimation: mainTween,
          trigger: `.image_wrapper.${gsapId}`,
          start: "left 60%",
          end: "+=100",
          scrub: 2,
          id: `.text_block.${gsapId}`,
          toggleActions: "play none none reset",
        },
        opacity: 1,
      });
    }
  }, [gsapId, mainTween]);

  return (
    <div className={`image_with_fading_text`} ref={main}>
      <div
        className={`image_wrapper ${gsapId ? gsapId : ""}`}
        onClick={() => {
          setCurrentPage(link);
        }}
      >
        <canvas ref={canvasRef} />
      </div>
      <div className={`text_block ${gsapId ? gsapId : ""}`}>
        <h1>{titre}</h1>
        <h3>{text}</h3>
      </div>
    </div>
  );
};

export default ImageWithFadingText;
