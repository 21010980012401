import React from "react";
import "../../css/DetailsPage/TextImageBlock.css";

const TextImageBlock = ({
  url = "/img/Image_2.png",
  titre = "Titre",
  text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus vel justo tempus accumsan. Phasellus libero mi, aliquet eget volutpat sed, volutpat id lorem. Integer sollicitudin mi in eleifend lobortis. Proin eu lacus sit amet est elementum semper quis at",
}) => {
  return (
    <>
      <div className="text_image_block">
        <div className="text">
          <h1>{titre}</h1>
          <h3>{text}</h3>
        </div>
        <div className="image_wrapper">
          <img src={url} alt="" />
        </div>
      </div>
    </>
  );
};
export default TextImageBlock;
