import React, { useEffect, useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import "../css/ImageWithScrollingText.css";
import ScrollTrigger from "gsap/ScrollTrigger";

const ImageWithScrollingText = ({
  mainTween = null,
  url = "/img/Image_1.png",
  texts = ["FlyTips", "414dev", "Demo", "Add new text"],
  gsapId = 4,
  setCursorColor = () => {},
}) => {
  const scrollingTextRef = useRef(null);

  const canvasRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    const img = new Image();

    img.src = url;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
    };

    function invertColor(rgb) {
      const luminance = 0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2];

      if (luminance > 120) return `rgb(80,80,80)`;
      else return `rgb(130,130,130)`;
    }

    function getPixelColor(event) {
      const x = event.offsetX;
      const y = event.offsetY;
      const pixel = ctx.getImageData(x, y, 1, 1).data;
      return [pixel[0], pixel[1], pixel[2]];
    }

    function updateSVGColor(event) {
      const rgb = getPixelColor(event);
      const oppositeColor = invertColor(rgb);
      setCursorColor(oppositeColor);
    }

    canvas.addEventListener("mousemove", updateSVGColor);

    return () => {
      canvas.removeEventListener("mousemove", updateSVGColor);
    };
  }, [setCursorColor, url]);

  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    const textElement = scrollingTextRef.current;
    const textWidth = textElement.offsetWidth;
    const textContainer = textElement.parentNode;

    gsap.set(textContainer.children, { x: (i) => i * textWidth });

    gsap.to(textContainer.children, {
      x: `-=${textWidth}`,
      duration: 15,
      ease: "none",
      repeat: -1,
      id: { gsapId },
      modifiers: {
        x: (x) => gsap.utils.wrap(-textWidth, 0, parseFloat(x)) + "px",
      },
    });
    if (mainTween) {
      gsap.to(`.image_wrapper.${gsapId}`, {
        scrollTrigger: {
          containerAnimation: mainTween,
          trigger: `.image_wrapper.${gsapId}`,
          start: "left 95%",
          end: "+=800",
          scrub: 2,
          id: `.image_wrapper.${gsapId}`,
          toggleActions: "play none none reset",
        },
        transform: "translateY(0)",
        opacity: 1,
      });
      ScrollTrigger.refresh();

      gsap.fromTo(
        `.horizontal-scrolling-texts.${gsapId}`,
        { opacity: 0 },
        {
          scrollTrigger: {
            containerAnimation: mainTween,
            trigger: `.image_wrapper.${gsapId}`,
            start: "left 60%",
            end: "+=100",
            scrub: 2,
            id: `.text_block.${gsapId}`,
            toggleActions: "play none none reset",
          },
          opacity: 1,
        }
      );
      ScrollTrigger.refresh();
    }
  }, [mainTween, gsapId]);

  return (
    <div className="image_with_scrolling_text">
      <div className={`image_wrapper ${gsapId ? gsapId : ""}`}>
        <canvas ref={canvasRef} />
      </div>
      <div className="wrapper">
        <div className={`${gsapId ? gsapId : ""} horizontal-scrolling-texts`}>
          <h1 className="horizontal-scrolling-text" ref={scrollingTextRef}>
            {texts.join(" ")}&nbsp;
          </h1>
          <h1 className="horizontal-scrolling-text">{texts.join(" ")}</h1>
          <h1 className="horizontal-scrolling-text">{texts.join(" ")}</h1>
        </div>
      </div>
    </div>
  );
};

export default ImageWithScrollingText;
