import React from "react";
import "../../css/ChangingImage.css";
import ChangingImageBackground from "./ChangingImageBackground";

const ChangingImage = ({
  urls = [
    "/img/Image_Déroulant_1_1.png",
    "/img/Image_Déroulant_1_2.png",
    "/img/Image_Déroulant_1_3.png",
  ],
  fading_text = "414",
}) => {
  return (
    <div className="changing_image">
      <div className="image_size">
        <ChangingImageBackground urls={urls} />
        <div className="fading_text">
          <h1>{fading_text}</h1>
        </div>
      </div>
    </div>
  );
};

export default ChangingImage;
