import React, { useState, useEffect } from "react";
import "../../css/ChangingImage.css";

const ChangingImageBackground = ({
  urls = [
    "/img/Image_Déroulant_1_1.png",
    "/img/Image_Déroulant_1_2.png",
    "/img/Image_Déroulant_1_3.png",
  ],
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % urls.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [urls.length]);

  return (
    <div>
      {urls.map((url, index) => (
        <img
          key={index}
          src={url}
          alt=""
          className={`${index === currentImageIndex ? "active" : ""} background`}
        />
      ))}
    </div>
  );
};

export default ChangingImageBackground;
