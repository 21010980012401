import { React } from "react";
import "../../css/DetailsPage/DetailsPage.css";
import ChangingImageAndBlackText from "./ChangingImageAndBlackText";
import TextImageBlock from "./TextImageBlock";
import ImageRectangle from "./ImageRectangle";
import PassiveAnimationPanel from "../../Components/AnimationPanel/PassivAnPanel";

const DetailsPage = ({
  setCurrentPage = () => {},
  urls = [
    "/img/Image_Déroulant_1_1.png",
    "/img/Image_Déroulant_1_2.png",
    "/img/Image_Déroulant_1_3.png",
  ],
  black_box_title = "Branding",
  black_box_content = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer at turpis nec enim ornare hendrerit. Nulla lacinia viverra odio eget.",
  TextImageBlock_url = "/img/Image_2.png",
  TextImageBlock_titre = "Titre",
  TextImageBlock_text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus vel justo tempus accumsan. Phasellus libero mi, aliquet eget volutpat sed, volutpat id lorem. Integer sollicitudin mi in eleifend lobortis. Proin eu lacus sit amet est elementum semper quis at",
  ImageRectangle_urls = [
    [
      "/img/Image_2.png",
      "/img/Image_2.png",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus vel justo tempus accumsan",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus vel justo tempus accumsan",
    ],
    [
      "/img/Image_2.png",
      "/img/Image_2.png",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus vel justo tempus accumsan",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus vel justo tempus accumsan",
    ],
  ],
}) => {
  return (
    <section className="DetailsPage">
      <PassiveAnimationPanel setCurrentPage={setCurrentPage} />
      <div className="behind_passive_an_panel" />
      <div className="details_wrapper">
        <ChangingImageAndBlackText
          urls={urls}
          black_box_content={black_box_content}
          black_box_title={black_box_title}
        />
        <TextImageBlock
          url={TextImageBlock_url}
          titre={TextImageBlock_titre}
          text={TextImageBlock_text}
        />
        <ImageRectangle urls={ImageRectangle_urls} />
      </div>
    </section>
  );
};

export default DetailsPage;
